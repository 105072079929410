export default class PlayerCard {
  id = 0

  /* eslint-disable camelcase */
  owner_okocha_id = 0

  name = 'no name'

  ranking = 0

  speed_score = 0

  stamina_score = 0

  dribbling_score = 0

  defending_score = 0

  passing_score = 0

  shooting_score = 0
  /* eslint-enable camelcase */

  score = 0
}
